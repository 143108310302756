import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Resources" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h1>{`Resources`}</h1>
    <h2>{`Mentorship`}</h2>
    <a target="_blank" rel="noopener noreferrer" href="https://www.designbuddies.community">Design Buddies -></a>  <br />
The world's largest design community. It's an inviting space to grow as a designer. I'm very active here.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.adplist.org">ADP List -></a>  <br />
Amazing Design Peoples List connects you with mentors. Maybe you can book a chat with me!
    <h2>{`Inspiration`}</h2>
    <a target="_blank" rel="noopener noreferrer" href="https://www.arun.is/about/">arun.is -></a>  <br />
Arun Venkatesan persuaded me to take on the daunting challenge of building a site. He has an excellent eye for design.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="http://simonpan.com">simonpan.com -></a>  <br />
Simon Pan is a Senior UX Designer at Google. His portfolio is THE golden standard. Study those case studies!  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.vitsoe.com/us/about/good-design">Dieter Ram's 10 principles -></a>  <br />
Design has no rules, but I don't think you'd be wrong to commit to these.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://creative.starbucks.com/">Starbucks Creative Expression -></a>  <br />
A beautiful design system by Starbucks.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://dribbble.com/JasonZigrino">Product renders -></a>  <br />
Jason Zigrino does the most wonderful 3D renders of timeless products.   <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/newcolossal/">3D Design -></a>  <br />
Mario Arnone is hands down my favorite 3D artist. He captures nostalgia and aesthetics like none other.   <br />  <br />
    <h2>{`Guides`}</h2>
    <a target="_blank" rel="noopener noreferrer" href="https://www.notion.so/UX-FAQs-3cbed70a2ce244208394a852af79996f">UX FAQs -></a>  <br />
When she's not arguing with me, Yichen answers some fantastic questions about being a UX designer.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://a11yresources.webflow.io/category/guides">Resources on accesibility -></a>  <br />
When you make your products accesible for some people, you actually make them more accesibile for everyone.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="http://www.jjg.net/ia/visvocab/">Language for diagrams -></a>  <br />
No matter how you draw diagrams, make sure you're consistent.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://atomicdesign.bradfrost.com/table-of-contents/">Designing Design Systems -></a>  <br />
All the tiny details in a design system should add up to a greater logic.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCQsVmhSa4X-G3lHlUtejzLA">Figma's videos -></a>  <br />
Figma's Youtube channel is brimming at the seams. <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.nngroup.com/">NN Group -></a>  <br />
If there were an authority on UX, it might be these guys.  <br />  <br />
    <h2>{`Assets`}</h2>
    <a target="_blank" rel="noopener noreferrer" href="https://www.ls.graphics"> Lstore -> </a>   <br />
Lstore has fantastic device mockups, and some of them are free!  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://www.ls.graphics"> GFX DATABASE -> </a>   <br />
Some of the best high quality graphics scans I've found.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://rsms.me/inter/"> Inter Font Family -> </a>   <br />
Want to use Apple's San Francisco font family? Is the law not letting you? Look no further! This is just as good.  <br />  <br />
    <h2>{`Tech`}</h2>
    <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.com">Gatsby -> </a>  <br />
Gatsby lets you create static sites. I'm not sure what that means. All I know is that Gatsby does the heavy-lifting for you when it comes to performance and search engine optimization.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://mdxjs.com"> MDX -> </a>   <br />
MDX lets you write Markdown with Javascript. HTML who?  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com"> Google Firebase -> </a>   <br />
I used to use Google Firebase to host my site. It costs pennies a month.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://render.com"> Render -> </a>   <br />
Now I use Render because it hosts your static sites for free.  <br />  <br />
    <a target="_blank" rel="noopener noreferrer" href="https://domains.google"> Google Domains -> </a>   <br />
Google Domains is the simplest way to buy and manage domains.  <br />  <br />
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      